import { SiteEntry } from "frontend/contentful/schema/universal";

// Get the unique site id for a site entry, which is either the domain or the slug

export function getSiteId(site?: SiteEntry) {
  if (site) {
    if (site.fields.slug === "/") return site.fields.domain;
    return site.fields.slug;
  }
}
